import { gql, useQuery } from '@apollo/client';
import {
	CommonServerSideProps,
	getCommonServerSideProps
} from 'api-common/common-server-side-props';
import {
	createSpecializationLinksCreator,
	filterProfessionByParentId
} from 'common/filters/create-specialization-links';
import {
	QueryParamsMapEnum,
	mapBooleanLike,
	mapBooleanToQueryParam
} from 'common/query-params-filter-enum';
import { createRoute, RoutesEnum } from 'common/routes';
import { SessionProvider } from 'common/session/session-context';
import { serializeQueryString } from 'common/url/serialize-query-string';
import {
	Footer,
	HomeForm,
	MainHeader,
	MainWrapper,
	PageFullWrapper
} from 'components/front';
import { EventsMap } from 'components/front/events-map';
import { NoResultsBanner } from 'components/front/no-results-banner';
import { SingleEvent } from 'components/front/single-event';
import dayjs from 'dayjs';
import { EventDto } from 'entities/event.dto';
import { ProfessionDto } from 'entities/profession.dto';
import { capitalize } from 'lodash';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-bootstrap-icons';
import { BsChevronRight } from 'react-icons/bs';
import { GoChevronRight } from 'react-icons/go';
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	Input,
	Spinner
} from 'reactstrap';

export const getServerSideProps = getCommonServerSideProps;

const QUERY_RETURN = `
addressess {
  building_no
  city
  id
  latitude
  local_no
  longitude
  postal_code
  street
	is_online
}
capacity
description
event_at
event_place
id
name
timespan
price
specialist {
  avatar_url
  shown_name
	id
  profession {
    name
  }
	user {
		id
		payment_expiration_at
	}
	opinions_aggregate {
		aggregate {
			avg {
				rating
			}
		}
	}
}
events_users_aggregate(
	where: { is_cancelled: { _eq: false }, is_rejected: { _eq: false } }
) {
	aggregate {
		count
	}
}
events_users {
	id
}
`;

const OFFLINE_QUERY = gql`
	query get_events_offline($city_slug: String = "", $profession_slug: String = "", $now: timestamptz) {
    professions {
		id
		name
		slug
		parent_id
		type
	}
		events(
			order_by: { event_at: asc_nulls_last }
			where: {
        is_cancelled: {_eq: false}
				event_at: {_gt: $now}
				addressess: { city: { _ilike: $city_slug } } 
				_or: [
					{ description: { _ilike: $profession_slug } }
					{ name: { _ilike: $profession_slug } }
					{specialist: {
						_or: [
							{ description: { _ilike: $profession_slug } }
							{ profession: { name: { _ilike: $profession_slug } } }
							{ shown_name: { _ilike: $profession_slug } }
							{
								secondary_professions_specialists: {
									profession: { name: { _ilike: $profession_slug } }
								}
							}
						]
					}	
				}			
				]
			}
		) {
		${QUERY_RETURN}
		}
	}
`;

const ONLINE_QUERY = gql`
	query get_events_online($city_slug: String = "", $profession_slug: String = "", $user_id: Int = 0, $now: timestamptz) {
    professions {
		id
		name
		slug
		parent_id
		type
	}
		events(
			order_by: { event_at: asc_nulls_last }
			where: {
        is_cancelled: {_eq: false}
				addressess: { is_online: { _eq: true } } 
				event_at: {_gt: $now}
				_or: [
					{ description: { _ilike: $profession_slug } }
					{ name: { _ilike: $profession_slug } }
					{
						specialist: {
							_or: [
										{ profession: { name: { _ilike: $profession_slug } } }
										{ shown_name: { _ilike: $profession_slug } }
										{
											secondary_professions_specialists: {
												profession: { name: { _ilike: $profession_slug } }
										}
									}
								]
							}
					}
				]
			}
		) {
		${QUERY_RETURN}
		}
	}
`;

const SPECIALIZATIONS_ID = 'specializations';

export const createSearchResultUrlForEvents = (
	specializationFilter: string,
	cityFilter?: string,
	onlineFilter?: boolean
) => {
	return (
		createRoute(RoutesEnum.Front_Events) +
		'?' +
		serializeQueryString({
			[QueryParamsMapEnum.Profession]: specializationFilter,
			[QueryParamsMapEnum.City]: cityFilter || '',
			[QueryParamsMapEnum.Online]: mapBooleanToQueryParam(onlineFilter || false)
		})
	);
};

export const createPrettySearchResultUrlForEvents = (
  specializationFilter: string,
  cityFilter?: string,
  onlineFilter?: boolean
) => {
  return createRoute(RoutesEnum.Front_EventsParametrized, {
    city: onlineFilter
      ? "online"
      : cityFilter
      ? cityFilter.replaceAll(" ", "_")
      : "",
    category: !!specializationFilter
      ? specializationFilter.replaceAll(" ", "_")
      : "wszystkie",
  });
};

export const mapSlugs = (slugPath: string[]) => {
  const professionSlug = slugPath && slugPath[0] ? slugPath[0] : "wszystkie";
  const citySlug = slugPath && slugPath[1] ? slugPath[1] : "";

  return {
    cityFilter: citySlug != "online" ? citySlug.replaceAll("_", " ") : "",
    professionFilter:
      professionSlug == "wszystkie" ? "" : professionSlug.replaceAll("_", " "),
    onlineFilter: citySlug == "online",
  };
};


const Wydarzenia: React.FC<CommonServerSideProps> = ({ userSession }) => {
	if (!userSession) {
		return null;
	}

	const router = useRouter();

	if (!userSession) {
		return null;
	}

	const [now, setNow] = useState(dayjs());

  const { professionFilter, cityFilter, onlineFilter } = mapSlugs(router.query?.slugPath as string[]);

	const { data, loading, refetch, error } = useQuery(
		onlineFilter ? ONLINE_QUERY : OFFLINE_QUERY,
		{
			variables: {
				profession_slug: `%${professionFilter || ''}%`,
				city_slug: `%${cityFilter || ''}%`,
				now: now.toDate()
			}
		}
	);

	const parentProfession = data
		? data.professions.find((profession: ProfessionDto) => {
				return (
					profession.name.toLowerCase() ===
					professionFilter?.toString().toLowerCase()
				);
		  })
		: null;

	const professionId = parentProfession ? parentProfession.id : 0;

	const isScrollable = () => {
		const element = document.getElementById(SPECIALIZATIONS_ID) as HTMLElement;
		// Compare the height to see if the element has scrollable content
		const hasScrollableContent = element.scrollWidth > element.clientWidth;

		// It's not enough because the element's `overflow-y` style can be set as
		// * `hidden`
		// * `hidden !important`
		// In those cases, the scrollbar isn't shown
		const overflowXStyle = window.getComputedStyle(element).overflowX;
		const isOverflowHidden = overflowXStyle.indexOf('hidden') !== -1;

		return hasScrollableContent && !isOverflowHidden;
	};

	const [modalOpen, setModalOpen] = useState(false);
	const [modalFilter, setModalFilter] = useState('');
	const [showSpecializationsButton, setShowSpecializationsButton] =
		useState(false);

	useEffect(() => {
		setShowSpecializationsButton(isScrollable());
	});

	useEffect(() => {
		setModalOpen(false);
	}, [router.query[QueryParamsMapEnum.Profession]]);

	const craeteSpecializationLinks = createSpecializationLinksCreator(
		data,
		professionId,
		cityFilter,
		onlineFilter,
		createPrettySearchResultUrlForEvents
	);

	const [formKey, setFormKey] = useState(0);

	useEffect(() => {
		setFormKey(formKey + 1);
	}, [
		// professionSlug,
		router.query[QueryParamsMapEnum.City],
		router.query[QueryParamsMapEnum.Profession],
		router.query[QueryParamsMapEnum.Online]
	]);

	return (
    <SessionProvider userSession={userSession}>
      <Head>
        <title>Wydarzenia - znany.pl</title>
        <meta name="keywords" content={["wydarzenia", professionFilter, cityFilter, onlineFilter].filter(item => !!item).join(",")} />
        <meta name="description" content={[professionFilter, cityFilter, onlineFilter].filter(item => !!item).join(",") + " - Wydarzenia na portalu znany.pl"} />
      </Head>
      <MainWrapper>
        <MainHeader />
        <div className="border-t border-b md:sticky z-20 top-0 bg-white px-2">
          <div className={`mx-auto lg:w-11/12 xl:w-10/12`}>
            <div className={`lg:w-11/12 xl:w-10/12 py-3 pt-16 lg:pt-3`}>
              <HomeForm
                searchType="specialist"
                key={formKey}
                filters={{
                  city: cityFilter?.toString(),
                  profession: professionFilter?.toString(),
                  online: onlineFilter,
                }}
                data={data}
                createSearchResultUrl={createPrettySearchResultUrlForEvents}
              />
            </div>
          </div>
        </div>
        <PageFullWrapper bg={` bg-gray-100`}>
          <div className={`mx-auto lg:w-11/12 xl:w-10/12`}>
            <div className="flex items-center justify-between">
              <div
                id={SPECIALIZATIONS_ID}
                className={`gap-4 rounded flex-grow flex items-center flex-nowrap p-2 text-sm text-gray-500 overflow-x-auto customscroll`}
              >
                {craeteSpecializationLinks()}
              </div>
              {showSpecializationsButton && (
                <div className="ml-auto pb-2">
                  <div
                    color="primary"
                    className="ml-4 flex items-center text-blue-600"
                    onClick={() => setModalOpen(true)}
                  >
                    <a className="hover:underline cursor-pointer text-sm font-bold">
                      więcej
                    </a>
                    <GoChevronRight />
                  </div>
                </div>
              )}
            </div>
            {modalOpen && (
              <Modal
                isOpen
                className="back-office overflow-y-hidden"
                style={{ width: "40%" }}
              >
                <ModalHeader toggle={() => setModalOpen(false)}>
                  <div className="ml-4">
                    {capitalize(
                      router.query[QueryParamsMapEnum.Profession] as string
                    )}{" "}
                    - specjalizacje
                  </div>
                </ModalHeader>
                <ModalBody>
                  <div className={`mb-2`}>
                    <Input
                      style={{ width: "100%" }}
                      placeholder="Szukaj specjalizacji"
                      onChange={(e) => setModalFilter(e.target.value)}
                    />
                  </div>
                  <div className={`justify-center items-center`}>
                    {data &&
                      data.professions &&
                      filterProfessionByParentId(
                        data?.professions,
                        professionId
                      )
                        .filter((p) =>
                          !!modalFilter
                            ? p.name
                                .toLowerCase()
                                .includes(modalFilter.toLowerCase())
                            : true
                        )
                        .map((p) => (
                          <Link
                            href={createPrettySearchResultUrlForEvents(
                              p.slug,
                              cityFilter?.toString(),
                              onlineFilter
                            )}
                          >
                            <a
                              className={`flex justify-between items-center hover:underline text-center p-1 py-2`}
                            >
                              <div className={`w-max`}>{p.name}</div>
                              <BsChevronRight />
                            </a>
                          </Link>
                        ))}
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    className="ml-auto"
                    onClick={() => setModalOpen(false)}
                  >
                    Zamknij
                  </Button>
                </ModalFooter>
              </Modal>
            )}

            <div className={`mt-4 grid grid-cols-3 gap-4`}>
              <div className="col-span-3 md:col-span-2">
                {data?.events?.length > 0 ? (
                  data.events.map((event: EventDto, index: number) => (
                    <SingleEvent refetch={refetch} key={index} event={event} />
                  ))
                ) : !loading ? (
                  <NoResultsBanner />
                ) : (
                  <div>
                    <Spinner />
                  </div>
                )}
              </div>

              {!onlineFilter && (
                <div className={`hidden md:grid w-full h-full`}>
                  {data?.events.length ? (
                    <EventsMap
                      cityFilter={cityFilter?.toString()}
                      events={data?.events}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>
          </div>
        </PageFullWrapper>
        <Footer />
      </MainWrapper>
    </SessionProvider>
  );
};

export default Wydarzenia;
