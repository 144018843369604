export enum QueryParamsMapEnum {
	Profession = 'specjalizacja',
	City = 'miasto',
	Online = 'online'
}

export interface SearchFilters {
	profession: string;
	city: string;
	online: boolean;
}

export const mapBooleanLike = (booleanLike?: string) => {
	return booleanLike === 'tak';
};

export const mapBooleanToQueryParam = (bool: boolean) => {
	return bool ? 'tak' : 'nie';
};
