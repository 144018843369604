import { gql, useMutation } from '@apollo/client';
import { createGoogleMapsLink } from 'common/maps/create-google-maps-link';
import { useSessionContext } from 'common/session/session-context';
import { JoinEventModal } from 'components/join-event-modal/join-event-modal';
import { SpecialistPanelCard } from 'components/specialist-panel-card/specialist-panel-card';
import dayjs from 'dayjs';
import { EventDto } from 'entities/event.dto';
import React, { useEffect, useState } from 'react';
import { FiCheck, FiPlus, FiSlash, FiVideo } from 'react-icons/fi';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import TextTruncate from 'react-text-truncate';
import { hasPremiumBasedOnUserSession } from 'common/premium/has-premium-based-on-expiration-date';
import { Price } from './price.component';

type Props = {
	event: EventDto;
	refetch: any;
};

const JOIN_EVENT_MUTATION = gql`
	mutation join_event(
		$email: String!
		$event_id: Int!
		$first_name: String!
		$last_name: String!
		$phone: String!
		$user_id: Int!
	) {
		join_event(
			email: $email
			event_id: $event_id
			first_name: $first_name
			last_name: $last_name
			phone: $phone
			user_id: $user_id
		) {
			success
		}
	}
`;

const LEAVE_EVENT = gql`
	mutation leave_event($user_id: Int!, $event_id: Int!) {
		delete_events_users(
			where: {
				_and: { event_id: { _eq: $event_id }, user_id: { _eq: $user_id } }
			}
		) {
			affected_rows
		}
	}
`;

export const SingleEvent: React.FC<Props> = ({ event, refetch }) => {
	const session = useSessionContext();

	const [isParticipating, setIsParticipating] = useState(
		session.session?.events_users?.some((e) => e.event_id === event.id)
	);

	const [showModal, setShowModal] = useState(false);
	const [eventReserving, setEventReserving] = useState(false);

	const [isTruncated, setIsTruncated] = useState<boolean>(true);

	const isFull =
		event.capacity === event.events_users_aggregate.aggregate.count;

	const shouldHideParticipationButton =
		session.session && session.session.role === 'place';

	const hasPremium = hasPremiumBasedOnUserSession(session);

	const [
		joinEvent,
		{ data: joinData, error: joinError, loading: joinLoading }
	] = useMutation(JOIN_EVENT_MUTATION);

	const [
		leaveEvent,
		{ data: leaveData, error: leaveError, loading: leaveLoading }
	] = useMutation(LEAVE_EVENT);

	const onClick = async (e: React.MouseEvent) => {
		e.preventDefault();

		if (!session || !session.session) {
			setEventReserving(true);
			return;
		}

		if (isParticipating) {
			const variables = {
				user_id: session.session.id,
				event_id: event.id
			};

			await leaveEvent({
				variables
			});

			setIsParticipating(false);
		} else {
			const {
				session: { first_name, last_name, phone, email }
			} = session;
			await joinEvent({
				variables: {
					event_id: event.id,
					user_id: session.session.id,
					first_name,
					last_name,
					phone,
					email
				}
			});

			setIsParticipating(true);
		}
	};

	useEffect(() => {
		if (!!joinData) {
			if (!joinData.join_event.success) {
				setShowModal(true);
			}
			refetch();
		}
	}, [joinData]);

	useEffect(() => {
		if (!!leaveData) refetch();
	}, [leaveData]);

	return (
		<div className='bg-white gap-2 mb-4 border border-gray-100 rounded-xl p-3'>
			{eventReserving && (
				<JoinEventModal
					onCancel={() => {
						setEventReserving(false);
					}}
					event={event}
				/>
			)}
			{showModal && (
				<Modal
					size='sm'
					isOpen={true}
					className='back-office mobile-fullscreen'
				>
					<ModalHeader toggle={() => setShowModal(false)}></ModalHeader>
					<ModalBody>
						Nie udało się zapisać na wydarzenie - w trakcie dołączania do
						wydarzenia zostało zarezerwowane ostatnie miejsce
					</ModalBody>
					<ModalFooter>
						<Button className='ml-auto' onClick={() => setShowModal(false)}>
							Rozumiem
						</Button>
					</ModalFooter>
				</Modal>
			)}
			<div>
				<div className=' flex flex-col md:flex-row justify-between'>
					<div>
						<div className='text-lg'>
							<span>
								{!!event.addressess.is_online && (
									<FiVideo className='mr-2 inline' />
								)}
								<strong>{event.name}</strong> -{' '}
								<Price price={event.price} hasDiscount={hasPremium} />
							</span>
						</div>
						<div className='text-sm'>
							{dayjs(event.event_at).format('DD.MM.YYYY')}{' '}
							{dayjs(event.event_at).format('HH:mm')}
							<span>&nbsp;-&nbsp;</span>
							{dayjs(event.event_at)
								.add(event.timespan, 'hours')
								.format('HH:mm')}
							,
							<br className='md:hidden' />
							<span className='hidden md:inline'> </span>
							{event.event_place}
							{!event.addressess.is_online && (
								<span>
									<a
										className='pl-4 text-blue-600 hover:underline'
										href={createGoogleMapsLink(event.addressess)}
										target='_blank'
										rel='noreferrer'
									>
										Mapa
									</a>
								</span>
							)}
						</div>
					</div>
					{!shouldHideParticipationButton && (
						<div className='my-3 md:my-0'>
							{isFull && !isParticipating ? (
								<div className='px-4 py-2 gap-2 border w-full justify-center mt-2 md:mt-0 text-gray-500 rounded inline-flex items-center'>
									<FiSlash />
									Brak&nbsp;miejsc
								</div>
							) : (
								<>
									{isParticipating && (
										<a
											onClick={onClick}
											className={`cursor-pointer
									inline-flex w-full lg:w-fit justify-center mt-2 md:mt-0 gap-2 border border-blue-600 text-blue-600 font-bold px-4 py-2 rounded 
									 items-start bg-blue-50`}
										>
											<FiCheck size={22} />
											<span>Bierzesz&nbsp;udział</span>
										</a>
									)}
									{!isParticipating && (
										<div>
											<Button
												size='md'
												color='primary'
												onClick={onClick}
												className=' inline-flex w-full lg:w-fit justify-center  items-center rounded-md '
											>
												<FiPlus className='mr-3' />
												<span>Weź&nbsp;udział</span>
											</Button>
										</div>
									)}
								</>
							)}
						</div>
					)}
				</div>

				{event.description && (
					<p className='my-3 text-sm pr-2'>
						{isTruncated && (
							<TextTruncate
								line={3}
								element='span'
								truncateText='…'
								text={event.description}
								textTruncateChild={
									<a
										className='cursor-pointer text-blue-700'
										onClick={() => setIsTruncated(false)}
									>
										Rozwiń
									</a>
								}
							/>
						)}
						{!isTruncated && (
							<span>
								{event.description}
								<div className='mt-4'>
									<a
										className='cursor-pointer text-blue-700'
										onClick={(event) => {
											event.preventDefault();
											setIsTruncated(true);
										}}
									>
										Zwiń
									</a>
								</div>
							</span>
						)}
					</p>
				)}
			</div>

			<div className='mt-3 border-t pt-3 flex flex-col md:flex-row'>
				<div className='md:w-1/2'>
					<div className='mb-1 text-sm'>Organizator:</div>
					<SpecialistPanelCard showStars specialist={event.specialist} />
				</div>
				<div className=' flex-grow flex md:block mt-2'>
					<div className='md:text-sm mr-1'>Pozostało miejsc:</div>
					<div>
						{event.capacity - event.events_users_aggregate.aggregate.count} z{' '}
						{event.capacity}
					</div>
				</div>
			</div>
		</div>
	);
};
