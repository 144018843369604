import { createRoute, RoutesEnum } from 'common/routes';
import { AddressDto } from 'entities/address.dto';
import { SpecialistDto } from 'entities/specialist.dto';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useRouter } from 'next/router';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import ReactMapboxGl, { Layer, Marker } from 'react-mapbox-gl';
import * as MapboxGl from 'mapbox-gl';
import { EventDto } from 'entities/event.dto';

export const getBounds = (
	markers: { latitude: number; longitude: number }[]
): [number, number, number, number] => {
	let left = 0;
	let right = 0;
	let top = 0;
	let bottom = 0;

	for (let i = 0; i < markers.length; i++) {
		const currentMarker = markers[i];

		if (!left) {
			left = currentMarker.latitude;
		}

		if (!right) {
			right = currentMarker.latitude;
		}

		if (!top) {
			top = currentMarker.longitude;
		}

		if (!bottom) {
			bottom = currentMarker.longitude;
		}

		if (currentMarker.latitude < left) {
			left = currentMarker.latitude;
		} else if (currentMarker.latitude > right) {
			right = currentMarker.latitude;
		}

		if (currentMarker.longitude < bottom) {
			bottom = currentMarker.longitude;
		} else if (currentMarker.longitude > top) {
			top = currentMarker.longitude;
		}
	}

	return [bottom, right, top, left];
};

interface Props {
	events: EventDto[];
	cityFilter?: string;
}

export const EventsMap: React.FC<Props> = ({ events, cityFilter = '' }) => {
	const style = {
		border: '2px solid #2563eb',
		borderRadius: '30px',
		width: '40px',
		height: '40px'
	};
	const accessToken = (process.env as any).NEXT_PUBLIC_MAPBOX_TOKEN.toString();

	const router = useRouter();

	const Map = ReactMapboxGl({
		accessToken
	});

	const layer = useRef<typeof Layer>(null);
	const map = useRef<MapboxGl.Map | null>(null);

	useEffect(() => {
		if (map && map.current && layer && layer.current) {
			layer.current;
		}
	}, [layer, layer.current, map, map.current]);

	if (!events) {
		return null;
	}

	return (
		<div>
			{accessToken ? (
				<Map
					onStyleLoad={(mapboxMap) => {
						map.current = mapboxMap;

						const allCoords = events
							.filter(
								(event) =>
									!event.addressess.is_online &&
									event.addressess.city
										.toLowerCase()
										.startsWith(cityFilter.toLowerCase())
							)
							.map(({ addressess: { latitude, longitude } }) => ({
								latitude,
								longitude
							}));

						mapboxMap.fitBounds(getBounds(allCoords), {
							animate: false,
							padding: {
								top: 10,
								bottom: 10,
								left: 10,
								right: 10
							}
						});
					}}
					center={[17.038538, 51.107883]}
					zoom={[13]}
					style={`mapbox://styles/mapbox/streets-v9`}
					containerStyle={{
						height: '90vh',
						marginBottom: 20,
						width: '100%',
						position: 'sticky',
						top: '20px'
					}}
				>
					{events
						?.filter((event) => !event.addressess.is_online)
						.map((event) => (
							<Marker
								coordinates={[
									event.addressess.longitude,
									event.addressess.latitude
								]}
								anchor='bottom'
								onClick={() => {
									router.push(
										createRoute(RoutesEnum.Front_Specialist, {
											id: event.specialist.id
										})
									);
								}}
							>
								<img
									style={style}
									className='cursor-pointer opacity-70 hover:opacity-100'
									src={event.specialist.avatar_url}
								/>
							</Marker>
						))}
				</Map>
			) : (
				<div>Insert MapBox valid token</div>
			)}
		</div>
	);
};
