import { ProfessionDto } from 'entities/profession.dto';
import Link from 'next/link';

export const filterProfessionByParentId = (
	professions: ProfessionDto[],
	parentId: number
) => {
	return professions.filter((profession) => {
		return profession.parent_id == parentId;
	});
};

export function createSpecializationLinksCreator(
	data: any,
	professionId: any,
	cityFilter: string | string[] | undefined,
	onlineFilter: boolean,
	createSearchResultUrl: (
		specializationFilter: string,
		cityFilter?: string,
		onlineFilter?: boolean,
		redirect?: boolean
	) => string
) {
	return (filter?: string) => {
		if (!data || !data.professions) return;

		return filterProfessionByParentId(data.professions, professionId)
			.filter((p) =>
				!!filter ? p.name.toLowerCase().includes(filter.toLowerCase()) : true
			)
			.map((el: ProfessionDto, i: number) => (
				<>
					{i != 0 && <div className='inline-flex items-center '>&#183;</div>}
					<div className={`inline-block`}>
						<Link
							href={createSearchResultUrl(
								el.name,
								cityFilter?.toString(),
								onlineFilter
							)}
						>
							<a
								className={`flex items-center text-center py-1 hover:underline`}
							>
								<div className={`w-max`}>{el.name}</div>
							</a>
						</Link>
					</div>
				</>
			));
	};
}
