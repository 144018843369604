import React from 'react';

type Props = {
	content?: string;
	slim?: boolean;
};

export const NoResultsBanner: React.FC<Props> = ({
	slim = false,
	content = 'Brak wyników z zadanych filtrów wyszukiwania'
}) => {
	return (
		<div
			className={`${
				slim ? 'p-2' : 'p-4'
			} bg-amber-500 mb-2 rounded-lg text-white`}
		>
			{content}
		</div>
	);
};
